// reducers.js
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import UserReducer from "./usersReducer";
import BrandsReducer from "./brandsReducer";
import SupplierReducer from "./suppliersReducer";
import StoresReducer from "./storesReducer";
import AttributesReducer from "./attributesReducer";
import ProductReducer from "./productReducer";
import AuthReducer from "./authReducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    users: UserReducer,
    brands: BrandsReducer,
    suppliers: SupplierReducer,
    stores: StoresReducer,
    attributes: AttributesReducer,
    products: ProductReducer,
    auth: AuthReducer,
    // rest of your reducers
  });
export default createRootReducer;
