import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  Form,
  Input,
  notification,
  Row,
  Col,
  Breadcrumb,
  Popover,
  Switch,
  Button,
  Modal,
} from "antd";
import Axios from "AxiosInstance";
import { AiOutlineTool } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { LeftOutlined } from "@ant-design/icons";
import "../Setting.css";
import { Link } from "react-router-dom";
import axios from "AxiosInstance";
import StyledButton from "components/Form/StyledButton";
import Loader from "Layouts/Loader";
import UserRole from "Utils/roles";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import UploadImageComponent from "components/UploadImageComponent";

const { Title, Text } = Typography;

function ThirdPartyToolsSettings(props) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [isFacebookPixelEnabled, setisFacebookPixelEnabled] = useState(false);
  const [isGoogleMerchantFeed, setisGoogleMerchantFeed] = useState(false);
  const [isGoogleAdwordsEnabled, setisGoogleAdwordsEnabled] = useState(false);
  const [isGoogleAnalyticsEnabled, setisGoogleAnalyticsEnabled] =
    useState(false);
  const [submitFormLoader, setsubmitFormLoader] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const GoogleMerchantFeedURL = `https://kuberdevblobstorage.blob.core.windows.net/merchantcdn/${localStorage.getItem(
    "merchantId"
  )}/product.xml`;
  const SitemapURL = `https://kuberdevblobstorage.blob.core.windows.net/merchantcdn/${localStorage.getItem(
    "merchantId"
  )}/sitemap.xml`;

  const [isShipstationSettingEnabled, setIsShipstationSettingEnabled] =
    useState(false);
  const [image, setImage] = useState({});
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  // const [isActiveShippingService, setIsActiveShippingService] = useState(false);
  const [showModelEnabled, setShowModelEnabled] = useState(false);
  // const [shipStationData, setShipStationData] = useState({});

  const getThirdPartyTools = async () => {
    try {
      setloading(true);
      const response = await axios.get("Merchant/thirdPartyToolsSettings", {
        headers: {
          sessionId: localStorage.getItem("sessionId"),
        },
      });
      form.setFieldsValue({
        googleAnalyticsCode: response.data && response.data.googleAnalyticsCode,
        facebookPixelId: response.data && response.data.facebookPixelId,
        googleAdwordsCode: response.data && response.data.googleAdwordsCode,
        isFacebookPixelEnabled:
          response.data && response.data.isFacebookPixelEnabled,
        isGoogleAdwordsEnabled:
          response.data && response.data.isGoogleAdwordsEnabled,
        isGoogleAnalyticsEnabled:
          response.data && response.data.isGoogleAnalyticsEnabled,
      });
      setisFacebookPixelEnabled(
        response.data && response.data.isFacebookPixelEnabled
      );
      setisGoogleAnalyticsEnabled(
        response.data && response.data.isGoogleAnalyticsEnabled
      );
      setisGoogleAdwordsEnabled(
        response.data && response.data.isGoogleAdwordsEnabled
      );
      setloading(false);
    } catch (e) {
      notification.error({ message: "Error getting third party data" });
      setloading(false);
    }
  };
  useEffect(() => {
    const userRole = UserRole(props.userRoles, "General Settings Management");
    setUserPermissions(userRole);
    getThirdPartyTools();
    getShipstationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // on submit
  const onFinish = async (values) => {
    setsubmitFormLoader(true);
    const sessionId = localStorage.getItem("sessionId");
    try {
      const postThirdPartyToolsSetting = await Axios.put(
        `/Merchant/thirdPartyToolsSettings`,
        values,
        {
          headers: {
            sessionId: sessionId,
          },
        }
      );
      if (postThirdPartyToolsSetting.status === 200) {
        onSuceess();
        setsubmitFormLoader(false);
      } else {
        onFailure();
        setsubmitFormLoader(false);
      }
    } catch (e) {
      onFailure();
      setsubmitFormLoader(false);
    }
  };

  //success notification
  const onSuceess = () => {
    notification.success({
      message: "Third Party tool updated successfully",
    });
    props.history.push("/settings");
  };

  //failure notification
  const onFailure = () => {
    notification.error({
      message: "There was some error Saving info of Third Party tool",
    });
  };

  const content = (
    <div className="facebook_p_id">
      <Text>Important</Text>
      <p>
        Nunc scelerisque tincidunt elit non mi ipsum. Cras pretium suscipit
        tellus sit amet aliquet.
      </p>
    </div>
  );

  if (loading) {
    return <Loader />;
  }

  const updateImage = (imageUploadResponse) => {
    const merchantId = localStorage.getItem("merchantId");
    const imageDetail = {
      imageId: imageUploadResponse.imageId,
      merchantId: merchantId,
      description: "ShipStation Image",
      title: "ShipStation Image",
      extension: imageUploadResponse.extension,
    };

    setImage(imageDetail);
  };

  const deleteImage = () => {
    setImage({});
  };

  const saveButtonDisable = (value) => {
    setDisableSaveButton(value);
  };

  const getShipstationData = async () => {
    try {
      setloading(true);
      const response = await axios.get("/Merchant/shippingSettings", {
        headers: {
          sessionId: localStorage.getItem("sessionId"),
        },
      });
      // setShipStationData(response?.data);
      if (response?.data?.shippingServiceDetails !== null) {
        form.setFieldsValue({
          shippingServiceName:
            response.data &&
            response.data.shippingServiceDetails[0] &&
            response.data.shippingServiceDetails[0].name,
          apiKey:
            response.data &&
            response.data.shippingServiceDetails[0] &&
            response.data.shippingServiceDetails[0].apiKey,
          apiSecret:
            response.data &&
            response.data.shippingServiceDetails[0] &&
            response.data.shippingServiceDetails[0].apiSecret,
        });
        setIsShipstationSettingEnabled(
          response.data && response.data.useKuberShippingService
        );
        // setIsActiveShippingService(
        //   response.data &&
        //     response.data.shippingServiceDetails[0] &&
        //     response.data.shippingServiceDetails[0].isActive
        // );
        setImage(
          response.data &&
            response.data.shippingServiceDetails[0] &&
            response.data.shippingServiceDetails[0].image
        );
      } else {
        setImage(null);
      }
      setloading(false);
    } catch (e) {
      notification.error({ message: "Error getting shipping station data" });
      setloading(false);
    }
  };

  const saveShipstationData = async (values) => {
    setsubmitFormLoader(true);
    let data;
    const sessionId = localStorage.getItem("sessionId");
    if (isShipstationSettingEnabled) {
      data = {
        useKuberShippingService: isShipstationSettingEnabled,
        shippingServiceDetails: [
          {
            isActive: isShipstationSettingEnabled,
            name: values.shippingServiceName,
            image: image,
            apiKey: values.apiKey,
            apiSecret: values.apiSecret,
          },
        ],
      };
    } else {
      data = {
        useKuberShippingService: isShipstationSettingEnabled,
        shippingServiceDetails: null,
      };
    }
    try {
      const postShipStationData = await Axios.put(
        `/Merchant/shippingSettings`,
        data,
        {
          headers: {
            sessionId: sessionId,
          },
        }
      );
      if (postShipStationData.status === 200) {
        notification.success({
          message: "Shipstation data saved successfully ",
        });
        setShowModelEnabled(false);
        setsubmitFormLoader(false);
        props.history.push("/settings");
      } else {
        notification.error({
          message:
            postShipStationData &&
            postShipStationData.response &&
            postShipStationData.response.data &&
            postShipStationData.response.data.message,
        });
        setsubmitFormLoader(false);
      }
    } catch (e) {
      notification.error({
        message: "There was some error Saving info of shipstation",
      });
      setShowModelEnabled(false);
      setsubmitFormLoader(false);
    }
  };

  return (
    <div style={{ maxWidth: 965, margin: "auto" }}>
      <div className="settings_top_title">
        <div className="back_top_pg">
          <Link to="/settings">
            <span className="back_in">
              <LeftOutlined />
            </span>
            <span>All Settings</span>
          </Link>
        </div>
        <div className="settings_title">
          <div className="sit_ticon">
            <AiOutlineTool />
          </div>
          <div className="sit_text">
            <Title>Third Party Tools</Title>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>Settings</Breadcrumb.Item>
              <Breadcrumb.Item>Third Party Tools</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <Card className="general_bxin">
        <Form name="basic" onFinish={onFinish} form={form}>
          <Row span={24} justify={"center"}>
            <Col span={24}>
              <div className="xml_sitemap_box">
                <Title level={5} style={{ margin: " 0px" }}>
                  XML Sitemap
                </Title>
                <Title level={5} type="secondary" style={{ margin: " 0px" }}>
                  Generate by our platform according to Goggle standard, the XML
                  Sitemap is meant for consumption by search engines and
                  contains a list of your webshop's pages, making sure Google
                  can find an crawl them all. <br />
                  If you want Google to find your XML Sitemap correctly, you
                  need to open your
                  <Link> Google Search Console</Link> account and add it there.
                </Title>
                <div className="xml_link">
                  <Input.Group compact>
                    <Input defaultValue={SitemapURL} />
                    <CopyToClipboard
                      text={SitemapURL}
                      onCopy={() => this.copyState({ copied: true })}
                    >
                      <Button type="primary">Copy URL</Button>
                    </CopyToClipboard>
                  </Input.Group>
                  <span>
                    Copy this URL and paste in into your Google account.
                  </span>
                </div>
              </div>
            </Col>
            <Col span={24} className="tpt_from_title">
              <div className="tools_title_left">
                <Title level={5} style={{ margin: " 0px" }}>
                  Google Analytics Code
                </Title>
                <Title level={5} type="secondary" style={{ margin: " 0px" }}>
                  Copy and paste your Google Analytics code here.
                </Title>{" "}
              </div>
              <div className="tools_title_right">
                <Form.Item name="isGoogleAnalyticsEnabled">
                  <Switch
                    onChange={(value) => setisGoogleAnalyticsEnabled(value)}
                    // valuePropName={isGoogleAnalyticsEnabled ? "checked" : ""}
                    defaultChecked={isGoogleAnalyticsEnabled}
                  />
                </Form.Item>
              </div>
            </Col>
            {isGoogleAnalyticsEnabled && (
              <Col span={24}>
                <div style={{ margin: "30px 0px" }}>
                  <label className="input-label">Google Analytics Code</label>
                  <Form.Item
                    rules={[
                      {
                        required: isGoogleAnalyticsEnabled,
                        message: "Please enter your Google Analytics Code!",
                      },
                    ]}
                    name="googleAnalyticsCode"
                  >
                    <Input className="input-field" type="text" size="large" />
                  </Form.Item>
                </div>
              </Col>
            )}
            <Col span={24} className="tpt_from_title">
              <div className="tools_title_left">
                <Title level={5} style={{ margin: " 0px" }}>
                  Google AdWords Code{" "}
                </Title>
                <Title level={5} type="secondary" style={{ margin: " 0px" }}>
                  Provide the Google Ads Account ID. Usually its something like
                  AW-123456789{" "}
                </Title>{" "}
              </div>
              <div className="tools_title_right">
                <Form.Item name="isGoogleAdwordsEnabled">
                  <Switch
                    onChange={(value) => setisGoogleAdwordsEnabled(value)}
                    // valuePropName={isGoogleAdwordsEnabled ? "checked" : ""}
                    defaultChecked={isGoogleAdwordsEnabled}
                  />
                </Form.Item>
              </div>
            </Col>
            {isGoogleAdwordsEnabled && (
              <Col span={24}>
                <div style={{ margin: "30px 0px" }}>
                  <label className="input-label">Google AdWords ID</label>
                  <Form.Item
                    rules={[
                      {
                        required: isGoogleAdwordsEnabled,
                        message: "Please enter your Google AdWords ID!",
                      },
                    ]}
                    name="googleAdwordsCode"
                  >
                    <Input className="input-field" type="text" size="large" />
                  </Form.Item>
                </div>
              </Col>
            )}
            <Col span={24} className="tpt_from_title">
              <div className="tools_title_left">
                <Title level={5} style={{ margin: " 0px" }}>
                  Facebook Pixel ID{" "}
                  <Popover content={content}>
                    <BsFillInfoCircleFill />
                  </Popover>
                </Title>
                <Title level={5} type="secondary" style={{ margin: " 0px" }}>
                  Find your Facebook Pixel ID from the this{" "}
                  <span style={{ color: "#EB4A1C", cursor: "pointer" }}>
                    link
                  </span>
                </Title>
              </div>
              <div className="tools_title_right">
                <Form.Item name="isFacebookPixelEnabled">
                  <Switch
                    onChange={(value) => setisFacebookPixelEnabled(value)}
                    // valuePropName={isFacebookPixelEnabled ? "checked" : ""}
                    defaultChecked={isFacebookPixelEnabled}
                  />
                </Form.Item>
              </div>
            </Col>
            {isFacebookPixelEnabled && (
              <Col span={24}>
                <div style={{ margin: "30px 0px" }}>
                  <label className="input-label">Facebook Pixel ID</label>
                  <Form.Item
                    name="facebookPixelId"
                    rules={[
                      {
                        required: isFacebookPixelEnabled,
                        message: "Please enter your Facebook Pixel ID!",
                      },
                    ]}
                  >
                    <Input className="input-field" type="text" size="large" />
                  </Form.Item>
                </div>
              </Col>
            )}

            <Col span={24} className="google_bot">
              <div className="tpt_from_title google_bot">
                <div className="tools_title_left">
                  <Title level={5} style={{ margin: " 0px" }}>
                    Google Merchant Feed{" "}
                  </Title>
                  <Title level={5} type="secondary" style={{ margin: " 0px" }}>
                    You can advertise all your products through Google Merchant
                    Center. Kuber helps you creating the XML file you can upload
                    in Google. Create your account now.
                  </Title>
                </div>
                <div className="tools_title_right">
                  <Form.Item name="isGoogleMerchantFeed">
                    <Switch
                      onChange={(value) => setisGoogleMerchantFeed(value)}
                      // valuePropName={isFacebookPixelEnabled ? "checked" : ""}
                      defaultChecked={isGoogleMerchantFeed}
                    />
                  </Form.Item>
                </div>
              </div>
              {isGoogleMerchantFeed && (
                <div className="xml_link">
                  <Input.Group compact>
                    <Input defaultValue={GoogleMerchantFeedURL} />
                    <CopyToClipboard
                      text={GoogleMerchantFeedURL}
                      onCopy={() => this.copyState({ copied: true })}
                    >
                      <Button type="primary">Copy URL</Button>
                    </CopyToClipboard>
                  </Input.Group>
                  <span>
                    Copy this URL and paste in into your Google account.
                  </span>
                </div>
              )}
            </Col>
            <Col span={24} className="shipstation _bot">
              <div className="tpt_from_title shipstation_bot">
                <div className="tools_title_left">
                  <Title level={5} style={{ margin: " 0px" }}>
                    Shipstation settings
                  </Title>
                  <Title level={5} type="secondary" style={{ margin: " 0px" }}>
                    Ready for the next step in Ecommerce? <br /> ShipStation is
                    the multichannel order & delivery management platform with
                    100+ integrations. Enable this module if you have an
                    account.
                  </Title>
                </div>
                <div className="tools_title_right">
                  <Form.Item name="isShipStationSetting">
                    <Button
                      type="primary"
                      onClick={() => setShowModelEnabled(true)}
                    >
                      Shipstation
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Col>

            {userPermissions.includes("Update") && (
              <Col span={24} className="tts_btn">
                <StyledButton
                  style={{
                    width: "148px",
                  }}
                  htmlType="submit"
                  type="primary"
                  loading={submitFormLoader}
                >
                  Save Info
                </StyledButton>
              </Col>
            )}
          </Row>
        </Form>
      </Card>
      {showModelEnabled && (
        <Modal
          centered
          visible={showModelEnabled}
          onOk={() => setShowModelEnabled(false)}
          onCancel={() => setShowModelEnabled(false)}
          footer={null}
          width={1000}
          className="video-modal"
        >
          <Form
            name="shipstationForm"
            onFinish={saveShipstationData}
            form={form}
          >
            <Row span={24} justify={"center"}>
              <Col span={24} className="ship-title">
                <Title level={4}>Shipstation Integration</Title>
              </Col>
              <Col span={24}>
                <div className="ship-inner">
                  <div className="tools_title_left">
                    <Title
                      level={5}
                      type="secondary"
                      style={{ margin: " 0px" }}
                    >
                      Ready for the next step in Ecommerce? <br /> ShipStation
                      is the multichannel order & delivery management platform
                      with 100+ integrations. Enable this module if you have an
                      account.
                    </Title>
                  </div>
                  <div className="tools_title_right">
                    <Form.Item name="isShipStationSetting">
                      <Switch
                        defaultChecked={isShipstationSettingEnabled}
                        onChange={(value) =>
                          setIsShipstationSettingEnabled(value)
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                {isShipstationSettingEnabled && (
                  <>
                    {/* <div className="tpt_from_title google_bot">
                      <div className="tools_title_left">
                        <Title level={5} style={{ margin: " 0px" }}>
                          IsActive Shipping Service
                        </Title>
                      </div>
                      <div className="tools_title_right">
                        <Form.Item name="IsActiveShippingService">
                          <Switch
                            defaultChecked={isActiveShippingService}
                            onChange={(value) =>
                              setIsActiveShippingService(value)
                            }
                          />
                        </Form.Item>
                      </div>
                    </div> */}
                    <div className="ship-show-box">
                      <div style={{ margin: "30px 0px" }}>
                        <label className="input-label">
                          Shipping Service Name
                        </label>
                        <Form.Item
                          name="shippingServiceName"
                          rules={[
                            {
                              required: isShipstationSettingEnabled,
                              message:
                                "Please enter your shipping service name!",
                            },
                          ]}
                        >
                          <Input
                            className="input-field"
                            type="text"
                            size="large"
                          />
                        </Form.Item>
                      </div>
                      <div style={{ margin: "30px 0px" }}>
                        <label className="input-label">API Key</label>
                        <Form.Item
                          name="apiKey"
                          rules={[
                            {
                              required: isShipstationSettingEnabled,
                              message: "Please enter your api key!",
                            },
                          ]}
                        >
                          <Input
                            className="input-field"
                            type="text"
                            size="large"
                          />
                        </Form.Item>
                      </div>
                      <div style={{ margin: "30px 0px" }}>
                        <label className="input-label">API Secret</label>
                        <Form.Item
                          name="apiSecret"
                          rules={[
                            {
                              required: isShipstationSettingEnabled,
                              message: "Please enter your api secret!",
                            },
                          ]}
                        >
                          <Input
                            className="input-field"
                            type="text"
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div>
                      <UploadImageComponent
                        imageTypeName="Shipping service image"
                        updateImage={updateImage}
                        initialImage={image}
                        deleteImage={deleteImage}
                        saveButtonDisable={saveButtonDisable}
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
            {userPermissions.includes("Update") && (
              <Col span={24} className="tts_btn ship-btn">
                <StyledButton
                  htmlType="submit"
                  type="primary"
                  disabled={disableSaveButton}
                  loading={submitFormLoader}
                >
                  Save
                </StyledButton>
              </Col>
            )}
          </Form>
        </Modal>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userRoles: state.auth.currentUser,
  };
};

export default connect(mapStateToProps, {})(ThirdPartyToolsSettings);
