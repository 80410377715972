import _find from "lodash/find";
import _map from "lodash/map";

function roles(props, moduleName) {
  const { modulePermissions } = props;
  const userPermissions = _find(modulePermissions, function (el) {
    return el.module.name === moduleName;
  });
  if (userPermissions) {
    const permissions = _map(userPermissions.permissions, function (el) {
      return el.name;
    });
    return permissions;
  }
  return [];
}

export default roles;
