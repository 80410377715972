import { GET_ALL_STORES, STORES_ERROR, STORES_LOADING } from "../ActionTypes";

const initialState = {
  stores: [],
  loading: false,
  error: {},
};

const BrandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STORES:
      return {
        ...state,
        loading: false,
        stores: action.payload,
      };
    case STORES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STORES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default BrandsReducer;
