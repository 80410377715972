import _find from "lodash/find";
import _map from "lodash/map";

function roles(props, moduleName) {
  const { role } = props;
  const userPermissions = _find(role.modulePermissions, function (el) {
    return el.module.name === moduleName;
  });
  const permissions = _map(userPermissions.permissions, function (el) {
    return el.name;
  });
  return permissions;
}

export default roles;
