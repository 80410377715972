//Define all the routes here and return key in this manner to get default selected route in side bar navigation

const getDefaultSelectedRoute = (pathname) => {
  switch (pathname) {
    case "/dashboard":
      return ["1"];
    case "/catalogue/products":
      return ["5"];
    case "/catalogue/products/add":
      return ["5"];
    case "/catalogue/brands":
      return ["6"];
    case "/catalogue/brands/add":
      return ["6"];
    case "/catalogue/suppliers":
      return ["7"];
    case "/catalogue/stores":
      return ["8"];
    case "/catalogue/attributes":
      return ["9"];
    case "/orders":
      return ["11"];
    case "/user/list":
      return ["16"];
    case "/user/add":
      return ["17"];
    case "/user/role":
      return ["18"];
    case "/user/role/add":
      return ["18"];
    case "/settings":
      return ["20"];
    case "/settings/general":
      return ["20"];
    case "/settings/store":
      return ["20"];
    case "/settings/selling":
      return ["20"];
    case "/settings/taxes":
      return ["20"];
    case "/settings/shipping":
      return ["20"];
    case "/settings/product":
      return ["20"];
    case "/settings/invoices":
      return ["20"];
    case "/settings/orders":
      return ["20"];
    case "/settings/tools":
      return ["20"];
    case " /content/pages":
      return ["21"];
    case "/content/news":
      return ["22"];
    case " /catalogue/inventory":
      return ["23"];
    case "/templates":
      return ["24"];
    case "/customers/customerslist":
      return ["31"];
    case "/reviews":
      return ["32"];
    case "/mediagallery":
      return ["36"];
    default:
      return ["1"];
  }
};

export default getDefaultSelectedRoute;
