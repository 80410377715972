import React, { Component, Suspense } from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
// import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "antd/dist/antd.less"; //importing from less because of customised theme
import "./custom.css";
import store, { history } from "./store";
import Loader from "Layouts/Loader";
import PrivateRoute from "./PrivateRoute";
import ErrorBoundary from "./components/ErrorBoundary";
import thirdPartyToolsSettings from "Screens/Settings/ThirdPartyToolsSettings/ThirdPartyToolsSettings";
import CacheBuster from "components/cacheBuster";
import PageNotFound from "Screens/PageNotFound";
const loginPage = React.lazy(() => import("Screens/Login/Login"));
const forgotPasswordPage = React.lazy(() =>
  import("Screens/ForgotPassword/ForgotPassword")
);
const resetPasswordPage = React.lazy(() =>
  import("Screens/ResetPassword/ResetPassword")
);

const settingsPage = React.lazy(() => import("./Screens/Settings"));
const generalSettingsPage = React.lazy(() =>
  import("Screens/Settings/GeneralSettings/GeneralSettings")
);
const ordersSettingsPage = React.lazy(() =>
  import("Screens/Settings/OrdersSettings/OrdersSettings")
);
const invoicesPage = React.lazy(() =>
  import("Screens/Settings/Invoices/Invoices")
);

const legalPage = React.lazy(() =>
  import("Screens/Settings/LegalPage/LegalPage")
);

const domainSettingsPage = React.lazy(() =>
  import("Screens/Settings/DomainAndDNS/DomainAndDNS")
);

const productSettingsPage = React.lazy(() =>
  import("Screens/Settings/ProductsSettings/ProductsSettings")
);
const yourStorePage = React.lazy(() =>
  import("Screens/Settings/YourStore/YourStore")
);
const emailNotificationsPage = React.lazy(() =>
  import("Screens/Settings/EmailNotifications/EmailNotifications")
);
const EmailNotificationTemplates = React.lazy(() =>
  import("Screens/Settings/EmailNotifications/EmailNotificationTemplates")
);
const taxesSettingsPage = React.lazy(() =>
  import("Screens/Settings/TaxesSettings/TaxesSettings")
);
const shippingSettingsPage = React.lazy(() =>
  import("Screens/Settings/ShippingSettings/ShippingSettings")
);
const InternationalSellingSettingsPage = React.lazy(() =>
  import("Screens/Settings/InternationalSelling/InternationalSelling")
);
const AddShippingRule = React.lazy(() =>
  import("Screens/Settings/ShippingSettings/AddNewRule")
);
const Dashboard = React.lazy(() => import("Screens/Dashboard/Dashboard"));
const UserList = React.lazy(() => import("Screens/Users/UserList"));

const AddUser = React.lazy(() => import("Screens/Users/AddUser"));

const UserRole = React.lazy(() => import("Screens/Users/UserRole"));
const AddUserRole = React.lazy(() => import("Screens/Users/AddUserRole"));
const ProductsList = React.lazy(() =>
  import("Screens/Catalogue/Products/ProductsList")
);
// const AddProduct = React.lazy(() =>
//   import("Screens/Catalogue/Products/AddProduct")
// );
const BrandsList = React.lazy(() =>
  import("Screens/Catalogue/Brands/BrandsList")
);
const AddBrand = React.lazy(() => import("Screens/Catalogue/Brands/AddBrand"));

const SupplierList = React.lazy(() =>
  import("Screens/Catalogue/Suppliers/SuppliersList")
);
const AddSupplier = React.lazy(() =>
  import("Screens/Catalogue/Suppliers/AddSupplier")
);
const StoreList = React.lazy(() =>
  import("Screens/Catalogue/Stores/StoreList")
);
const AddStore = React.lazy(() => import("Screens/Catalogue/Stores/AddStore"));

const AttributeList = React.lazy(() =>
  import("Screens/Catalogue/Attributes/AttributesList")
);
const AddAttribute = React.lazy(() =>
  import("Screens/Catalogue/Attributes/AddAttribute")
);

const CategoryList = React.lazy(() =>
  import("Screens/Catalogue/Categories/CategoriesList")
);
const AddCategory = React.lazy(() =>
  import("Screens/Catalogue/Categories/AddCategory")
);

const AddProduct = React.lazy(() =>
  import("Screens/Catalogue/Products/CreateProduct/AddProduct")
);

const OrderList = React.lazy(() => import("Screens/Orders/OrderListTab"));

const OrderDetails = React.lazy(() =>
  import("Screens/Orders/OrderDetails/Index")
);

const OrderInvoices = React.lazy(() =>
  import("Screens/Orders/OrderInvoices/Invoices")
);

const EditVariantPage = React.lazy(() =>
  import(
    "Screens/Catalogue/Products/CreateProduct/CreateVariant/Componants/EditVariant"
  )
);
const AddContentPage = React.lazy(() =>
  import("Screens/Contents/Pages/AddPage")
);
const ContentPageList = React.lazy(() =>
  import("Screens/Contents/Pages/ContentPagesList")
);
const AddNewsPage = React.lazy(() => import("Screens/Contents/News/AddNews"));

const AddNewsCategory = React.lazy(() =>
  import("Screens/Contents/News/AddNewCategory")
);

const ContentNewsList = React.lazy(() =>
  import("Screens/Contents/News/ContentNewsList")
);

const InventoryList = React.lazy(() =>
  import("Screens/Catalogue/Inventory/InventoryList")
);
const MediaGallery = React.lazy(() => import("Screens/Media/MediaGallery"));
const TemplateList = React.lazy(() =>
  import("Screens/WebsiteTemplate/Templates")
);
const TemplateData = React.lazy(() =>
  import("Screens/WebsiteTemplate/TemplateData")
);
const CouponsList = React.lazy(() => import("Screens/Coupons/CouponsList"));

const AddCouponsPage = React.lazy(() => import("Screens/Coupons/CouponForm"));

const GeneralReport = React.lazy(() => import("Screens/Report/General"));

const CustomersList = React.lazy(() =>
  import("Screens/Customers/CustomersList")
);

const NewcustomerForm = React.lazy(() =>
  import("Screens/Customers/NewcustomerForm")
);

const Reviews = React.lazy(() => import("Screens/Customers/Reviews"));

const ReviewsDetail = React.lazy(() =>
  import("Screens/Customers/ReviewsDetail")
);
const CustomersDetail = React.lazy(() =>
  import("Screens/Customers/CustomersDetail")
);

const ImportCSV = React.lazy(() =>
  import("Screens/Catalogue/Import/ImportCSV")
);
const ExcelImport = React.lazy(() =>
  import("Screens/Catalogue/Import/ImportExcelFile")
);

const IntroductionGuide = React.lazy(() =>
  import("Screens/IntroductionGuide/IntroductionGuide")
);

export default class App extends Component {
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return (
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <ErrorBoundary>
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      {/* Login Routes */}
                      <Route exact path="/" component={loginPage} />
                      {/* Forgotpassword Routes */}
                      <Route
                        exact
                        path="/forgot"
                        component={forgotPasswordPage}
                      />
                      {/* Resetpassword Routes */}
                      <Route
                        exact
                        path="/resetpasswordwithtoken/"
                        component={resetPasswordPage}
                      />
                      {/* Dashboard Routes */}
                      <PrivateRoute
                        exact
                        path="/dashboard"
                        component={Dashboard}
                      />
                      {/* User Routes */}
                      <PrivateRoute
                        exact
                        path="/user/list"
                        component={UserList}
                      />
                      <PrivateRoute
                        exact
                        path="/user/add"
                        component={AddUser}
                      />
                      <PrivateRoute
                        exact
                        path="/user/edit/:id"
                        component={AddUser}
                      />
                      {/* User Role Routes */}
                      <PrivateRoute
                        exact
                        path="/user/role"
                        component={UserRole}
                      />
                      <PrivateRoute
                        exact
                        path="/user/role/add"
                        component={AddUserRole}
                      />
                      {/* Product Routes */}
                      <PrivateRoute
                        exact
                        path="/catalogue/products"
                        component={ProductsList}
                      />
                      <PrivateRoute
                        // exact
                        path="/catalogue/products/add"
                        component={AddProduct}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/products/edit/:id"
                        component={AddProduct}
                      />
                      {/* Brand Routes */}
                      <PrivateRoute
                        exact
                        path="/catalogue/brands"
                        component={BrandsList}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/brands/add"
                        component={AddBrand}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/brands/edit/:id"
                        component={AddBrand}
                      />
                      <PrivateRoute
                        exact
                        path="/setting/shipping/add"
                        component={AddShippingRule}
                      />
                      <PrivateRoute
                        exact
                        path="/setting/shipping/:id"
                        component={AddShippingRule}
                      />
                      {/* Suppliers Routes */}
                      <PrivateRoute
                        exact
                        path="/catalogue/suppliers"
                        component={SupplierList}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/suppliers/add"
                        component={AddSupplier}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/suppliers/edit/:id"
                        component={AddSupplier}
                      />
                      {/* Store Routes */}
                      <PrivateRoute
                        exact
                        path="/catalogue/stores"
                        component={StoreList}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/stores/add"
                        component={AddStore}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/stores/edit/:id"
                        component={AddStore}
                      />
                      {/* Settings Routes */}
                      <PrivateRoute
                        exact
                        path="/settings"
                        component={settingsPage}
                      />
                      <PrivateRoute
                        exact
                        path="/settings/general"
                        component={generalSettingsPage}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/settings/emailnotifications"
                        component={emailNotificationsPage}
                      />
                      <PrivateRoute
                        exact
                        path="/settings/emailnotifications/customer/:type"
                        component={EmailNotificationTemplates}
                      />
                      <PrivateRoute
                        exact
                        path="/settings/emailnotifications/staff/:type"
                        component={EmailNotificationTemplates}
                      />
                      <PrivateRoute
                        exact
                        path="/settings/tools"
                        component={thirdPartyToolsSettings}
                      />
                      <PrivateRoute
                        exact
                        path="/settings/orders"
                        component={ordersSettingsPage}
                      />
                      <PrivateRoute
                        exact
                        path="/settings/invoices"
                        component={invoicesPage}
                      />
                      <PrivateRoute
                        exact
                        path="/settings/legal"
                        component={legalPage}
                      />
                      <PrivateRoute
                        exact
                        path="/settings/store"
                        component={yourStorePage}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/settings/selling"
                        component={InternationalSellingSettingsPage}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/settings/taxes"
                        component={taxesSettingsPage}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/settings/shipping"
                        component={shippingSettingsPage}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/settings/product"
                        component={productSettingsPage}
                      />
                      <PrivateRoute
                        exact
                        path="/settings/domain"
                        component={domainSettingsPage}
                      />
                      {/* Attributes Routes */}
                      <PrivateRoute
                        exact
                        path="/catalogue/attributes"
                        component={AttributeList}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/attribute/add"
                        component={AddAttribute}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/attribute/edit/:id"
                        component={AddAttribute}
                      />
                      {/* categories Routes */}
                      <PrivateRoute
                        exact
                        path="/catalogue/categories"
                        component={CategoryList}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/categories/add"
                        component={AddCategory}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/categories/edit/:id"
                        component={AddCategory}
                      />
                      <PrivateRoute
                        exact
                        path="/orders"
                        component={OrderList}
                      />
                      <PrivateRoute
                        exact
                        path="/invoices"
                        component={OrderInvoices}
                      />
                      <PrivateRoute
                        exact
                        path="/order/:id"
                        component={OrderDetails}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/inventory"
                        component={InventoryList}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/import"
                        component={ImportCSV}
                      />
                      <PrivateRoute
                        exact
                        path="/catalogue/excelimport"
                        component={ExcelImport}
                      />
                      {/* Content Routes */}
                      <PrivateRoute
                        exact
                        path="/content/pages"
                        component={ContentPageList}
                      />
                      <PrivateRoute
                        exact
                        path="/content/pages/add"
                        component={AddContentPage}
                      />
                      <PrivateRoute
                        exact
                        path="/content/pages/edit/:id"
                        component={AddContentPage}
                      />
                      <PrivateRoute
                        exact
                        path="/content/news"
                        component={ContentNewsList}
                      />
                      <PrivateRoute
                        exact
                        path="/content/news/add"
                        component={AddNewsPage}
                      />
                      <PrivateRoute
                        exact
                        path="/content/news/edit/:id"
                        component={AddNewsPage}
                      />
                      <PrivateRoute
                        exact
                        path="/content/news/addnewcategory"
                        component={AddNewsCategory}
                      />
                      {/* Variant Routes*/}
                      <PrivateRoute
                        exact
                        path="/catalogue/product/variant/edit/:id"
                        component={EditVariantPage}
                      />
                      {/* Website Templates Routes*/}
                      <PrivateRoute
                        exact
                        path="/templates"
                        component={TemplateList}
                      />
                      <PrivateRoute
                        exact
                        path="/templates/templatedata"
                        component={TemplateData}
                      />
                      <PrivateRoute
                        exact
                        path="/coupons"
                        component={CouponsList}
                      />
                      <PrivateRoute
                        exact
                        path="/coupons/add"
                        component={AddCouponsPage}
                      />
                      <PrivateRoute
                        exact
                        path="/coupons/edit/:id"
                        component={AddCouponsPage}
                      />
                      <PrivateRoute
                        exact
                        path="/report/general"
                        component={GeneralReport}
                      />
                      <PrivateRoute
                        exact
                        path="/customers/customerslist"
                        component={CustomersList}
                      />
                      <PrivateRoute
                        exact
                        path="/customers/newcustomerform"
                        component={NewcustomerForm}
                      />
                      <PrivateRoute exact path="/reviews" component={Reviews} />
                      <PrivateRoute
                        exact
                        path="/mediagallery"
                        component={MediaGallery}
                      />
                      <PrivateRoute
                        exact
                        path="/reviews/:id"
                        component={ReviewsDetail}
                      />
                      <PrivateRoute
                        exact
                        path="/customers/:id"
                        component={CustomersDetail}
                      />
                      <PrivateRoute
                        exact
                        path="/introductionguide"
                        component={IntroductionGuide}
                      />
                      {/* Page Not Found */}
                      <Route path="*">
                        {/* TODO:Add No page found page  */}
                        <PageNotFound />
                      </Route>
                    </Switch>
                  </Suspense>
                </ErrorBoundary>
              </ConnectedRouter>
            </Provider>
          );
        }}
      </CacheBuster>
    );
  }
}
