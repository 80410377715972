import { GET_ALL_ATTRIBUTES, ATTRIBUTES_ERROR, ATTRIBUTES_LOADING } from "../ActionTypes";

const initialState = {
  attributes: [],
  loading: false,
  error: {},
};

const AttributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ATTRIBUTES:
      return {
        ...state,
        loading: false,
        attributes: action.payload,
      };
    case ATTRIBUTES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ATTRIBUTES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default AttributesReducer;
