//Common button . it will receive all the props
import React from "react"; // useState
import { Button } from "antd";

export default function StyledButton(props) {
  // const [hover, sethover] = useState(false);
  const { type } = props;

  return (
    <>
      <Button type={type} {...props} className="styled-button">
        {props.children}
      </Button>
    </>
  );
}
