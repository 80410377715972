import {
  GET_ALL_SUPPLIERS,
  SUPPLIERS_LOADING,
  SUPPLIERS_ERROR,
} from "../ActionTypes";

const initialState = {
  suppliers: [],
  loading: false,
  error: {},
};

const SuppliersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SUPPLIERS:
      return {
        ...state,
        loading: false,
        suppliers: action.payload,
      };
    case SUPPLIERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SUPPLIERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default SuppliersReducer;
