import {
  GET_ALL_USERS,
  USER_ERROR,
  USER_LOADING,
  GET_ALL_SYSTEM_MODULES,
  GET_ALL_USER_PERMISSIONS,
  GET_ALL_USER_ROLES,
} from "../ActionTypes";

const initialState = {
  users: [],
  loading: false,
  error: {},
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_SYSTEM_MODULES:
      return {
        ...state,
        loading: false,
        systemModules: action.payload,
      };
    case GET_ALL_USER_PERMISSIONS:
      return {
        ...state,
        loading: false,
        systemPermissions: action.payload,
      };

    case GET_ALL_USER_ROLES:
      return {
        ...state,
        loading: false,
        systemRoles: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
