import * as React from "react";

function PageNotFound(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={651.188}
      height={402.531}
      {...props}
    >
      <g data-name="Group 4374">
        <g data-name="Group 4373">
          <g data-name="Group 4363">
            <path
              data-name="Path 4496"
              d="M452.559 142.18l-112.15 6.94L331.5 5.154 414.771.001l30.577 25.645z"
              fill="#ebebeb"
            />
            <path
              data-name="Path 4497"
              d="M414.771.001l30.577 25.645-28.879 1.787z"
              fill="#dbdbdb"
            />
            <g data-name="Group 4362">
              <path
                data-name="Path 4498"
                d="M383.682 96.867l-.434-3.247c-1.131-6.731.712-14.258 6.561-22.134 5.272-6.964 8.134-12.032 7.818-17.675-.358-6.4-4.61-10.437-12.52-10.12a22.327 22.327 0 00-12.45 4.6l-3.453-7.734c3.971-3.242 11.008-5.649 17.655-6.021 14.424-.808 21.445 7.732 21.979 17.264.477 8.53-3.944 14.942-9.564 22.429-5.153 6.831-6.851 12.462-6.117 18.838l.308 3.254zm-1.776 17.714c-.26-4.64 2.693-8.077 7.083-8.323s7.583 2.847 7.842 7.488c.246 4.389-2.448 7.937-7.089 8.2-4.39.24-7.59-2.979-7.836-7.368z"
                fill="#dbdbdb"
              />
            </g>
          </g>
          <g data-name="Group 4364">
            <path
              data-name="Path 4499"
              d="M651.186 138.7l-9.57 46.344-6.156-6.006-10.483 7.472-8.007-9.941-17.966 13.057-15.343-25.67-17.123 10.031-6.788-9.363-21.742 12.25-6.542-14 15.239-73.811 81.7 16.869z"
              fill="#ebebeb"
            />
            <path
              data-name="Path 4500"
              d="M628.409 105.926l22.779 32.767-28.336-5.85z"
              fill="#dbdbdb"
            />
            <path
              data-name="Path 4501"
              d="M639.58 194.307l-14.035 67.995L515.5 239.587l13.93-67.454 6.542 14 21.742-12.25 6.788 9.363 17.124-10.02 15.343 25.67 17.966-13.06 8.007 9.941 10.483-7.472z"
              fill="#ebebeb"
            />
          </g>
          <g data-name="Group 4361">
            <g data-name="Group 4348">
              <ellipse
                data-name="Ellipse 204"
                cx={286.742}
                cy={31.42}
                rx={286.742}
                ry={31.42}
                transform="translate(69.5 339.693)"
                fill="#f1f1f1"
              />
            </g>
            <g data-name="Group 4354">
              <g data-name="Group 4351">
                <g data-name="Group 4349" fill="#d13b11">
                  <path
                    data-name="Path 4476"
                    d="M511.936 377.869H207.257l44.056-249.851h304.678z"
                  />
                  <path
                    data-name="Path 4477"
                    d="M355.589 102.143h-99.714l-4.562 25.875h106.706z"
                  />
                </g>
              </g>
              <path
                data-name="Path 4480"
                d="M511.489 377.869H206.154l-43.351-240.781h305.335z"
                fill="#eb4a1c"
              />
              <g
                data-name="Group 4353"
                fill="#fff"
                transform="translate(-64.381 -54.182)"
              >
                <circle
                  data-name="Ellipse 205"
                  cx={12.691}
                  cy={12.691}
                  r={12.691}
                  transform="translate(320.298 313.08)"
                />
                <circle
                  data-name="Ellipse 206"
                  cx={12.691}
                  cy={12.691}
                  r={12.691}
                  transform="translate(420.623 313.08)"
                />
                <path
                  data-name="Path 4481"
                  d="M401.38 340.488l-.7-1.329c-.064-.12-6.62-12.239-19.812-12.239-12.031 0-14.964 11.411-15.083 11.9l-.356 1.454-2.91-.7.35-1.455c.034-.142 3.575-14.192 18-14.192 15.037 0 22.172 13.281 22.468 13.846l.7 1.328z"
                />
              </g>
            </g>
            <g data-name="Group 4360">
              <path
                data-name="Path 4482"
                d="M491.226 270.132c17.728-3.808 33.742-17.05 35.1-32.851 2.658-31-35.1-48.124-31.2-83.947 3.72-34.17 39.561-36.609 53.93-31.885 15.869 5.217 10.432 20.47-3.937 18.5-24.909-3.412-40.567-30.493-28.736-61.212 11.219-29.131 43.7-35.232 43.7-35.232"
                fill="none"
                stroke="#2f373b"
                strokeMiterlimit={10}
                strokeWidth={2}
                strokeDasharray={4}
              />
              <g data-name="Group 4359">
                <path
                  data-name="Path 4483"
                  d="M575.938 37.982c-2.552-7.9-19.632-5.064-16.3 5.262s18.852 2.642 16.3-5.262z"
                  fill="#2f373b"
                />
                <g data-name="Group 4356">
                  <path
                    data-name="Path 4484"
                    d="M550.351 34.795c.127-3.466 4.949-6.133 11.766-4.9s13.236 5.823 13.236 5.823-6.74 4.124-13.628 4.851-11.506-2.291-11.374-5.774z"
                    fill="#ebebeb"
                  />
                  <g data-name="Group 4355" fill="#263238">
                    <path
                      data-name="Path 4485"
                      d="M575.353 35.718c-3.539.025-7.073-.094-10.608-.2-3.532-.152-7.066-.291-10.594-.577 3.54-.026 7.074.094 10.608.2 3.533.148 7.066.289 10.594.577z"
                    />
                    <path
                      data-name="Path 4486"
                      d="M557.202 31.726a28.187 28.187 0 012.924 1.61 27.727 27.727 0 012.726 1.927 27.46 27.46 0 01-2.925-1.61 27.89 27.89 0 01-2.725-1.927z"
                    />
                    <path
                      data-name="Path 4487"
                      d="M566.468 35.388a31.078 31.078 0 01-2.839 2.049 30.429 30.429 0 01-3.04 1.735 32.631 32.631 0 015.879-3.784z"
                    />
                    <path
                      data-name="Path 4488"
                      d="M567.496 33.436a7.451 7.451 0 012.586 2.085 7.041 7.041 0 01-1.411-.9 7.142 7.142 0 01-1.175-1.185z"
                    />
                  </g>
                </g>
                <g data-name="Group 4358">
                  <path
                    data-name="Path 4489"
                    d="M556.846 55.368c2.13 2.737 7.6 2.08 12.411-2.905S576.589 40 576.589 40s-7.878.6-13.891 4.036-7.993 8.582-5.852 11.332z"
                    fill="#ebebeb"
                  />
                  <g data-name="Group 4357" fill="#263238">
                    <path
                      data-name="Path 4490"
                      d="M576.589 40c-2.885 2.05-5.682 4.213-8.486 6.368-2.777 2.189-5.561 4.369-8.256 6.663 2.886-2.049 5.683-4.212 8.486-6.367 2.777-2.19 5.561-4.37 8.256-6.664z"
                    />
                    <path
                      data-name="Path 4491"
                      d="M564.198 53.85a28.223 28.223 0 001.431-3.015 27.848 27.848 0 001.084-3.157 27.656 27.656 0 00-1.431 3.015 28.03 28.03 0 00-1.084 3.157z"
                    />
                    <path
                      data-name="Path 4492"
                      d="M569.573 45.462a32.723 32.723 0 00-6.982.368 30.517 30.517 0 003.5 0 31 31 0 003.482-.368z"
                    />
                    <path
                      data-name="Path 4493"
                      d="M571.549 46.443a7.083 7.083 0 00.619-1.552 6.972 6.972 0 00.259-1.651 6.988 6.988 0 00-.62 1.552 7.092 7.092 0 00-.258 1.651z"
                    />
                  </g>
                </g>
                <path
                  data-name="Path 4494"
                  d="M575.938 37.982a3 3 0 111.93-3.772 3 3 0 01-1.93 3.772z"
                  fill="#6e767a"
                />
                <path
                  data-name="Path 4495"
                  d="M577.779 43.685a3 3 0 111.931-3.772 3 3 0 01-1.931 3.772z"
                  fill="#6e767a"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 4370">
            <g data-name="Shadow">
              <g data-name="Group 4348">
                <ellipse
                  data-name="Ellipse 204"
                  cx={74.374}
                  cy={12.302}
                  rx={74.374}
                  ry={12.302}
                  fill="#f1f1f1"
                  stroke="rgba(0,0,0,0)"
                  transform="translate(.5 321.868)"
                />
              </g>
            </g>
            <g data-name="Group 4365">
              <path
                data-name="Path 4502"
                d="M79.064 269.969a273.579 273.579 0 00-.4-29.035 369.647 369.647 0 00-9.1-59.153c-2.536-10.947-5.66-21.709-8.914-32.459-.1-.324-.616-.233-.527.106 10.065 37.895 18.322 77 17.753 116.4-.159 11.025-1.435 21.853-2.482 32.808-.028.293.454.41.521.109 2.083-9.379 2.756-19.202 3.149-28.776z"
                fill="#ebebeb"
              />
              <path
                data-name="Path 4503"
                d="M60.129 149.428s-15.246 38.7-11.832 47.793 10.45 11.037 10.45 11.037-9.328 13.318-2.809 25.809 21 11.9 21.964 26.063c0 0 .807-59.219-17.773-110.702z"
                fill="#ebebeb"
              />
              <path
                data-name="Path 4504"
                d="M76.245 235.678a.3.3 0 00-.016-.2 354.376 354.376 0 00-14.788-77.848c-.023-.075-.146-.05-.126.027q4.532 16.98 8.006 34.144a16.84 16.84 0 00-7.278-4.058.041.041 0 00-.027.078 19.272 19.272 0 017.514 5.021q.669 3.363 1.295 6.733c-3.406-3.807-8.132-5.7-12.86-7.493-.066-.025-.1.078-.037.1 4.925 2.005 9.829 4.588 13.166 8.831q1.744 9.633 3.061 19.331a18.427 18.427 0 00-9.091-5.21c-.106-.026-.165.131-.058.162a19.2 19.2 0 019.221 5.906.247.247 0 00.056.043q.459 3.411.863 6.83a23.478 23.478 0 00-6.372-3.843c-.062-.026-.1.071-.035.1a21.906 21.906 0 016.383 4.224c.026.025.053.022.081.032.254 2.168.507 4.335.726 6.507a30.988 30.988 0 00-12.931-9.233c-.1-.038-.151.114-.055.156a33.7 33.7 0 0112.743 9.806.284.284 0 00.322.1q.493 4.995.869 10.007c0 .045.078.05.076 0q-.289-5.125-.708-10.253z"
                fill="#fff"
              />
              <path
                data-name="Path 4505"
                d="M63.995 183.894a19.064 19.064 0 00-9.16-2.855c-.076-.007-.082.1-.008.116a36.308 36.308 0 019.057 2.958c.131.056.224-.14.111-.219z"
                fill="#fff"
              />
              <path
                data-name="Path 4506"
                d="M65.846 224.865a19.293 19.293 0 00-6.876-2.351c-.084-.014-.091.115-.01.13a25.765 25.765 0 016.8 2.385c.096.046.175-.11.086-.164z"
                fill="#fff"
              />
            </g>
            <g data-name="Group 4366">
              <path
                data-name="Path 4507"
                d="M119.144 197.853a103.592 103.592 0 00-17.647 15.426 63.361 63.361 0 00-12.376 20.987 156.9 156.9 0 00-9.219 50.2 121.967 121.967 0 002.717 28.229.269.269 0 00.528-.1 158.356 158.356 0 01.276-49.345c2.622-16.009 6.734-33.425 16.911-46.435 5.541-7.085 12.449-12.658 19.279-18.418.358-.311-.1-.804-.469-.544z"
                fill="#ebebeb"
              />
              <path
                data-name="Path 4508"
                d="M82.146 272.935a94.86 94.86 0 0112.863-16.442c7.627-7.625 14.051-13.045 15.042-18.088s-7.64-8.757-7.64-8.757 10.555 1.895 13.442-2.534 4.282-30.085 4.282-30.085-19.2 13.709-28.147 32.926-9.842 42.98-9.842 42.98z"
                fill="#ebebeb"
              />
              <path
                data-name="Path 4509"
                d="M113.492 203.606c-8.079 7.935-16.21 16.391-20.968 26.816a102.924 102.924 0 00-4.241 11.057c0 .006 0 .012-.006.017-.245.759-.494 1.515-.725 2.279a.287.287 0 00-.039.124 217.647 217.647 0 00-5.455 23.7c-.009.051.068.077.079.025.827-4 1.74-8.045 2.759-12.075a.286.286 0 00.115-.022 16.791 16.791 0 015.039-1.28c.114-.01.106-.21-.011-.2a18.9 18.9 0 00-4.984.909c.93-3.633 1.941-7.257 3.078-10.829a53.6 53.6 0 0111.388-1.489c.039 0 .046-.059 0-.061a43.048 43.048 0 00-11.2.95c.2-.613.4-1.222.609-1.832a57.654 57.654 0 017.578-1.284c.046-.005.042-.083 0-.082a36.789 36.789 0 00-7.42.9c1.044-3.09 2.173-6.138 3.426-9.107a55.172 55.172 0 013.625-7.039 46.355 46.355 0 016.147-1.1c.059-.005.052-.1-.005-.1a16.573 16.573 0 00-5.747.568 73.286 73.286 0 013.206-4.777c4.306-.953 8.67-1.838 13.075-1.011.076.014.1-.114.029-.134-4.194-1.1-8.355-.466-12.528.359q.684-.908 1.392-1.8a28.565 28.565 0 018.045-1.264.045.045 0 00.007-.089 20.991 20.991 0 00-7.5.666c3.56-4.423 7.492-8.577 11.379-12.685.185-.187.045-.298-.147-.11z"
                fill="#fff"
              />
              <path
                data-name="Path 4510"
                d="M113.277 221.094a17.557 17.557 0 00-3.819-.133.138.138 0 00.014.272c1.264.037 2.528-.057 3.793.012a.076.076 0 00.012-.151z"
                fill="#fff"
              />
              <path
                data-name="Path 4511"
                d="M99.457 245.986a17.3 17.3 0 00-5.918 1.188c-.048.017-.021.1.028.084 1.954-.465 3.931-.654 5.905-.98.161-.03.154-.292-.015-.292z"
                fill="#fff"
              />
              <path
                data-name="Path 4512"
                d="M95.315 237.386a12.552 12.552 0 00-2.512.227.076.076 0 00.007.15 12.61 12.61 0 002.518-.118.131.131 0 00-.013-.259z"
                fill="#fff"
              />
            </g>
            <g data-name="Group 4367">
              <path
                data-name="Path 4513"
                d="M25.858 225.079a87.23 87.23 0 0117.846 8.4 53.329 53.329 0 0114.867 14.13A132.057 132.057 0 0177.634 286.1a102.6 102.6 0 014.325 23.474.226.226 0 01-.45.041 133.265 133.265 0 00-11.628-39.87c-5.822-12.349-13.176-25.494-24.418-33.671-6.122-4.452-13-7.366-19.86-10.449-.36-.162-.104-.67.255-.546z"
                fill="#ebebeb"
              />
              <path
                data-name="Path 4514"
                d="M73.154 277.286a79.9 79.9 0 00-14.211-10.333c-7.934-4.408-14.385-7.309-16.354-11.161s4.159-8.853 4.159-8.853-8.1 3.973-11.464 1.056-10.419-23.357-10.419-23.357 18.708 6.656 30.389 20.14 17.9 32.508 17.9 32.508z"
                fill="#ebebeb"
              />
              <path
                data-name="Path 4515"
                d="M72.179 273.074c-9.356-17.562-21.841-33.45-38.949-43.937-.008 0-.016.006-.008.011a85.95 85.95 0 0112.522 9.8 19.834 19.834 0 00-10.059 2.123.058.058 0 00.051.105 27.562 27.562 0 0110.349-1.749.209.209 0 00.118-.044q.911.864 1.8 1.752a31.831 31.831 0 00-5.375.713.038.038 0 00.015.074 27.454 27.454 0 015.677-.468 117.666 117.666 0 018.824 10.019 8.972 8.972 0 00-3.758.273.072.072 0 00.027.142 20.088 20.088 0 014.012-.055c.512.651 1.03 1.3 1.531 1.96a36.288 36.288 0 00-11.354 1.1.044.044 0 00.017.087 45.717 45.717 0 0111.662-.757c.477.633.929 1.284 1.4 1.924a24.625 24.625 0 00-6.759.774c-.085.021-.061.155.026.135a25.845 25.845 0 017-.545 188.14 188.14 0 015.794 8.477 6.315 6.315 0 00-2.966.178.042.042 0 00.015.082 12.92 12.92 0 013.211.151q2.5 3.891 4.82 7.849c.122.225.482.059.357-.174z"
                fill="#fff"
              />
              <path
                data-name="Path 4516"
                d="M57.625 258.506a14.521 14.521 0 00-5.436.665c-.049.016-.037.091.016.081a42.049 42.049 0 015.405-.554.1.1 0 00.015-.192z"
                fill="#fff"
              />
              <path
                data-name="Path 4517"
                d="M41.306 237.103a9.336 9.336 0 00-2.507.146c-.1.015-.067.159.029.148.824-.088 1.637-.057 2.461-.081a.107.107 0 00.017-.213z"
                fill="#fff"
              />
            </g>
            <g data-name="Group 4369">
              <g data-name="Group 4368">
                <path
                  data-name="Path 4518"
                  d="M97.284 335.366H55.055l6.021-44.108 1.539-11.309h27.109l1.539 11.309z"
                  fill="#dbdbdb"
                />
                <path
                  data-name="Path 4519"
                  d="M91.262 291.259H61.071l1.539-11.309h27.109z"
                  fill="#c7c7c7"
                />
              </g>
              <path
                data-name="Rectangle 1553"
                fill="#dbdbdb"
                d="M58.738 276.027H93.6v9.578H58.738z"
              />
            </g>
          </g>
          <g data-name="Group 4371" fill="#ebebeb">
            <path
              data-name="Path 4520"
              d="M136.485 100.014L125.199 94.9l-1.565 3.456a2.945 2.945 0 01-2.572 1.714 17.118 17.118 0 00-1.845.181 2.944 2.944 0 01-2.85-1.18l-2.209-3.083-10.077 7.22 2.209 3.083a2.945 2.945 0 01.2 3.078 17.261 17.261 0 00-.765 1.688 2.944 2.944 0 01-2.45 1.884l-3.776.371 1.217 12.332 3.789-.377a2.951 2.951 0 012.766 1.381 16.675 16.675 0 001.062 1.483 2.942 2.942 0 01.42 3.049l-1.577 3.481 11.286 5.118 1.572-3.463a2.945 2.945 0 012.575-1.711 17.483 17.483 0 001.837-.178 2.941 2.941 0 012.847 1.18l2.21 3.084 10.077-7.219-2.21-3.085a2.944 2.944 0 01-.2-3.075 17.138 17.138 0 00.76-1.683 2.946 2.946 0 012.448-1.887l3.785-.375-1.217-12.332-3.787.374a2.941 2.941 0 01-2.755-1.379q-.247-.384-.517-.761t-.55-.728a2.952 2.952 0 01-.421-3.063zm-6.4 11.409a10.151 10.151 0 11-14.163-2.346 10.143 10.143 0 0114.161 2.346z"
            />
            <path
              data-name="Path 4521"
              d="M593.388 298.613l-7.519 4.339 1.329 2.3a2.063 2.063 0 01-.073 2.164 12.169 12.169 0 00-.65 1.125 2.062 2.062 0 01-1.834 1.141h-2.658v8.685h2.658a2.063 2.063 0 011.834 1.141 12.169 12.169 0 00.65 1.125 2.063 2.063 0 01.073 2.164l-1.329 2.3 7.519 4.339 1.331-2.312a2.065 2.065 0 011.915-1.011c.212.011.426.016.639.016s.427 0 .639-.016a2.06 2.06 0 011.908 1.005l1.339 2.318 7.519-4.339-1.33-2.308a2.063 2.063 0 01.075-2.164 12.2 12.2 0 00.649-1.119 2.061 2.061 0 011.833-1.14h2.659v-8.685h-2.659a2.062 2.062 0 01-1.833-1.139 11.945 11.945 0 00-.649-1.119 2.065 2.065 0 01-.075-2.165l1.33-2.308-7.519-4.339-1.332 2.309a2.062 2.062 0 01-1.91 1.007 12.469 12.469 0 00-1.283 0 2.069 2.069 0 01-1.916-1.01zm3.885 8.3a7.111 7.111 0 11-7.114 7.108 7.106 7.106 0 017.114-7.107z"
            />
            <path
              data-name="Path 4522"
              d="M159.307 63.063l-6.9 5.273 1.616 2.111a2.062 2.062 0 01.206 2.155 12.011 12.011 0 00-.5 1.2 2.063 2.063 0 01-1.672 1.369l-2.635.343 1.121 8.611 2.635-.343a2.065 2.065 0 011.967.9 12.021 12.021 0 00.789 1.032 2.061 2.061 0 01.352 2.136l-1.021 2.454 8.016 3.332 1.022-2.465a2.065 2.065 0 011.768-1.249q.318-.025.636-.067t.632-.1a2.059 2.059 0 012.021.75l1.627 2.126 6.9-5.273-1.617-2.117a2.062 2.062 0 01-.2-2.156 12.011 12.011 0 00.5-1.194 2.065 2.065 0 011.672-1.367l2.635-.343-1.12-8.611-2.636.343a2.065 2.065 0 01-1.966-.894 11.914 11.914 0 00-.787-1.026 2.062 2.062 0 01-.355-2.136l1.022-2.461-8.017-3.332-1.022 2.461a2.064 2.064 0 01-1.764 1.246 11.914 11.914 0 00-1.272.167 2.067 2.067 0 01-2.031-.755zm4.924 7.73a7.112 7.112 0 11-6.138 7.967 7.106 7.106 0 016.138-7.967z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PageNotFound;
