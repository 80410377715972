//auth actions
export const LOGIN_USER = "LOGIN_USER";

//user actions
export const GET_ALL_USERS = "GET_ALL_USERS";
export const USER_LOADING = "USER_LOADING";
export const USER_ERROR = "USER_ERROR";

//user role actions
export const GET_ALL_USER_ROLES = "GET_ALL_USER_ROLES";
export const GET_ALL_SYSTEM_MODULES = "GET_ALL_SYSTEM_MODULES";
export const GET_ALL_USER_PERMISSIONS = "GET_ALL_USER_PERMISSIONS";

// brands Actions
export const GET_ALL_BRANDS = "GET_ALL_BRANDS";
export const BRANDS_LOADING = "BRANDS_LOADING";
export const BRANDS_ERROR = "BRANDS_ERROR";

// suppliers Actions
export const GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS";
export const SUPPLIERS_LOADING = "SUPPLIERS_LOADING";
export const SUPPLIERS_ERROR = "SUPPLIERS_ERROR";

// stores Actions
export const GET_ALL_STORES = "GET_ALL_STORES";
export const STORES_LOADING = "STORES_LOADING";
export const STORES_ERROR = "STORES_ERROR";

// attributes Actions
export const GET_ALL_ATTRIBUTES = "GET_ALL_ATTRIBUTES";
export const ATTRIBUTES_LOADING = "ATTRIBUTES_LOADING";
export const ATTRIBUTES_ERROR = "ATTRIBUTES_ERROR";

// attributes Actions
export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";
export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";

// ROLES Actions
export const GET_USER_ROLE = "GET_USER_ROLE";
export const ROLE_LOADING = "ROLE_LOADING";
export const USER_ROLE_ERROR = "USER_ROLE_ERROR";
