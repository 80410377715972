import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  notification,
  Typography,
  Upload,
  Button,
  Popover,
} from "antd";
//import { CloseCircleOutlined } from "@ant-design/icons";
import "./UploadImage.css";
import StyledButton from "./Form/StyledButton";
import axios from "AxiosInstance";
import getImageUrl from "Utils/getImageUrl";
import Uploadbackimg from "Assets/upload_bg.png";
import Uploadarrowimg from "Assets/upl_aicon.png";

const UploadImageComponent = (props) => {
  const { Text } = Typography;

  const [imageTypeName, setImageTypeName] = useState(props.imageTypeName);
  const [imageFile, setImageFile] = useState(null);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [newImageUploaded, setNewImageUploaded] = useState(false);
  const [showCancelDeleteActionButton, setShowCancelDeleteActionButton] =
    useState(false);
  // const [currentImageId, setCurrentImageId] = useState(false);
  const [imageFileUrl, setImageFileUrl] = useState(null);
  const [showDescription, setShowDescription] = useState(false);
  const [loading, setLoading] = useState(false);

  let [form] = Form.useForm();

  const {
    initialImage,
    showTitle,
    placement,
    imageId,
    deleteImage,
    onRemove,
    data,
    GalleryimageIndex,
    saveButtonDisable,
    useFor,
    attributeIndex,
  } = props;

  useEffect(() => {
    if (props.imageTypeName) {
      setImageTypeName(props.imageTypeName);
      if (props.imageTypeName === "Gallery Image" && initialImage) {
        form.setFieldsValue({
          title: initialImage.title,
          description: initialImage.description,
        });
      }
    }

    if (props.showDescription) {
      setShowDescription(props.showDescription);
    }

    if (initialImage || imageId) {
      if (imageId) {
        const imageUrl = getImageUrl(
          imageId,
          "original",
          initialImage && initialImage.extension
            ? initialImage.extension
            : "jpeg"
        );
        setImageFileUrl(imageUrl);
        setShowUploadButton(false);
        // setCurrentImageId(imageId);
      } else {
        if (initialImage) {
          const imageUrl = getImageUrl(
            initialImage && initialImage.imageId,
            "original",
            initialImage.extension
          );

          setImageFileUrl(imageUrl);
          setShowUploadButton(false);
          // setCurrentImageId(initialImage.imageId);
        } else {
          setImageFileUrl(null);
        }
      }
    } else {
      setImageFileUrl(null);
      // setShowUploadButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.imageTypeName, props.showDescription, initialImage, imageId]);

  const onChangeFile = (fileUpload) => {
    const uploadedImage = fileUpload.file;
    if (uploadedImage) {
      const fileSize = uploadedImage.size / 1024 / 1024;
      if (fileSize > 10) {
        notification.error({ message: "Image should be less than 10Mb" });
        return;
      }
      setImageFile(uploadedImage);
      setImageFileUrl(URL.createObjectURL(uploadedImage));
      setShowUploadButton(true);
      setNewImageUploaded(false);
      setShowCancelDeleteActionButton(true);
    }
  };

  const onUploadImage = async (formData) => {
    saveButtonDisable && saveButtonDisable(true);
    const { updateImage } = props;

    if (imageFile) {
      notification.open({
        message: "Image Upload",
        description: "Image uploading and processing started.",
      });
      let imageUploadResponse = "";
      const sessionId = localStorage.getItem("sessionId");
      let file = new FormData();
      file.append("FormFile", imageFile);
      file.append("title", formData.title);
      file.append("description", formData.description);

      setLoading(true);
      const response = await axios.post(`image`, file, {
        headers: {
          "Content-Type": "multipart/form-data",
          sessionId: sessionId,
        },
      });

      if (response.status !== 201) {
        notification.error({ message: response.data });
        setLoading(false);
        saveButtonDisable && saveButtonDisable(false);
      } else {
        notification.success({ message: "Image uploaded." });
        imageUploadResponse = response.data;
        setLoading(false);
        saveButtonDisable && saveButtonDisable(false);
      }

      if (GalleryimageIndex >= 0) {
        if (useFor && attributeIndex >= 0) {
          imageUploadResponse.attributeIndex = attributeIndex && attributeIndex;
        }
        updateImage(imageUploadResponse, GalleryimageIndex);
      } else {
        if (useFor && attributeIndex >= 0) {
          imageUploadResponse.attributeIndex = attributeIndex && attributeIndex;
        }
        data !== undefined
          ? updateImage(imageUploadResponse, data)
          : updateImage(imageUploadResponse);
      }
      setNewImageUploaded(true);
      setShowCancelDeleteActionButton(true);
      // saveButtonDisable && saveButtonDisable(false);
    } else {
      saveButtonDisable && saveButtonDisable(false);
      if (initialImage) {
        //update image description and title in existing image
        initialImage.title = formData.title;
        initialImage.description = formData.description;
        if (useFor && attributeIndex >= 0) {
          initialImage.attributeIndex = attributeIndex && attributeIndex;
        }

        if (GalleryimageIndex >= 0) {
          updateImage(initialImage, GalleryimageIndex);
        } else {
          updateImage(initialImage);
        }

        setNewImageUploaded(true);
        setShowCancelDeleteActionButton(true);
      }
    }
  };

  const beforeUpload = (file) => {
    setImageFile(file);
    return false;
  };

  const handleDeleteImage = () => {
    setImageFileUrl(null);

    setImageFileUrl(null);
    if (deleteImage) {
      if (attributeIndex >= 0) {
        deleteImage(imageTypeName, attributeIndex);
      }
      GalleryimageIndex >= 0
        ? deleteImage(imageTypeName, GalleryimageIndex)
        : deleteImage(imageTypeName);
      // attributeIndex >= 0 && deleteImage(imageTypeName, attributeIndex);
      setShowCancelDeleteActionButton(false);
      setNewImageUploaded(false);
    } else {
      //TODO: Need to remove this else after all side implementation
      setShowCancelDeleteActionButton(true);
      setNewImageUploaded(false);
    }
  };

  const PopMessage = (
    <div style={{ padding: 10 }}>
      <p>Make sure to click update button </p>
      <p>when you change the image </p>
    </div>
  );

  const changeInputField = () => {
    if (initialImage) {
      setNewImageUploaded(false);
      setShowUploadButton(true);
    } else {
      if (attributeIndex >= 0) {
        setNewImageUploaded(false);
        setShowUploadButton(true);
      }
    }
  };

  return (
    <Form
      form={form}
      onFinish={onUploadImage}
      initialValues={{
        title: initialImage && initialImage.title,
        description: initialImage && initialImage.description,
      }}
    >
      <Row gutter={[16, 16]}>
        {!useFor && (
          <Col span={24} className="upload_text">
            {imageTypeName && (
              <Text>{imageTypeName ? imageTypeName : "Image"}</Text>
            )}
            {imageTypeName && (
              <Text type="secondary">Drop your image here.</Text>
            )}
          </Col>
        )}
        <Col xs={24} lg={showDescription || showTitle ? 12 : 24} sm={24}>
          <Row className="upload-area" align="middle">
            <div
              className={imageFileUrl ? "upload_in_background " : "upload_in"}
            >
              <Popover
                content={PopMessage}
                title="Upload Image"
                placement={placement ? placement : "left"}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Upload
                  name={props.name}
                  listType="picture-card"
                  showUploadList={false}
                  onChange={onChangeFile}
                  beforeUpload={beforeUpload}
                  accept="image/*"
                >
                  {imageFileUrl && (
                    <>
                      <img
                        src={imageFileUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    </>
                  )}
                  {!imageFileUrl && (
                    <div className="upload_bx_img">
                      <div className="up_i">
                        <img src={Uploadbackimg} className="upload-bk" alt="" />
                        <img
                          src={Uploadarrowimg}
                          className="upload-arrow"
                          alt=""
                        />
                      </div>
                      <div className="ulp_text_btn">
                        <Text> Drop your image here, or</Text>
                        <Text type="secondary">jpg, png (upto 10MB)</Text>
                        <Button type="primary">Choose File</Button>
                      </div>
                    </div>
                  )}
                </Upload>
              </Popover>

              {imageFileUrl && (
                <div className="delete-icon">
                  <span onClick={handleDeleteImage}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Group_2161"
                      data-name="Group 2161"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="Path_3073"
                        data-name="Path 3073"
                        className="uploadimgcls-1"
                        d="M0,0H24V24H0Z"
                      />
                      <line
                        id="Line_238"
                        data-name="Line 238"
                        className="uploadimgcls-2"
                        x2="16"
                        transform="translate(4 7)"
                      />
                      <line
                        id="Line_239"
                        data-name="Line 239"
                        className="uploadimgcls-2"
                        y2="6"
                        transform="translate(10 11)"
                      />
                      <line
                        id="Line_240"
                        data-name="Line 240"
                        className="uploadimgcls-2"
                        y2="6"
                        transform="translate(14 11)"
                      />
                      <path
                        id="Path_3074"
                        data-name="Path 3074"
                        className="uploadimgcls-2"
                        d="M5,7,6,19a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2L19,7"
                      />
                      <path
                        id="Path_3075"
                        data-name="Path 3075"
                        className="uploadimgcls-2"
                        d="M9,7V4a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1V7"
                      />
                    </svg>
                  </span>
                  {/* <CloseCircleOutlined
                    onClick={handleDeleteImage}
                    style={{ fontSize: "25px" }}
                  /> */}
                </div>
              )}

              <div className="btn_bottom">
                {showUploadButton &&
                  (imageFileUrl || (attributeIndex >= 0 && !imageFileUrl)) && (
                    <StyledButton
                      htmlType="submit"
                      type="primary"
                      loading={loading}
                      disabled={newImageUploaded}
                    >
                      {loading
                        ? `Updating`
                        : newImageUploaded
                        ? "Updated"
                        : `Update`}
                    </StyledButton>
                  )}
                {showCancelDeleteActionButton && (
                  <StyledButton htmlType="button" onClick={handleDeleteImage}>
                    Cancel
                  </StyledButton>
                )}
                {onRemove && (
                  <StyledButton
                    htmlType="button"
                    onClick={() => onRemove(GalleryimageIndex)}
                  >
                    Remove
                  </StyledButton>
                )}
              </div>
            </div>
          </Row>
        </Col>

        <Col xs={24} lg={showDescription || showTitle ? 12 : 24} sm={24}>
          <Row gutter={[0, 0]}>
            {showTitle && (
              <Col span={24} style={{ marginTop: "15px" }}>
                <label className="input-label">Title</label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "The title for the image.",
                    },
                  ]}
                  name="title"
                  onChange={changeInputField}
                >
                  <Input className="input-field" size="large" />
                </Form.Item>
              </Col>
            )}
            {showDescription && (
              <Col span={24} style={{ marginTop: "15px" }}>
                <label className="input-label">Description (Alt Text)</label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please enter description!",
                    },
                  ]}
                  name="description"
                  onChange={changeInputField}
                >
                  <Input.TextArea
                    className="input-field"
                    maxLength={160}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default UploadImageComponent;
