//Common Loader of the system
import React from "react";
import "./layout.css";
import { Spin } from "antd";
export default function Loader(props) {
  const { size } = props;
  return (
    <div className="loader">
      <Spin tip="Loading..." size={size ? size : "large"} />
    </div>
  );
}
