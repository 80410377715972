import React from "react";
import { Row, Col, Typography, Button } from "antd";
import PageNotFoundSVG from "svgs/PageNotFound";
import { useHistory } from "react-router-dom";

function PageNotFound() {
  const { Title, Text } = Typography;
  const history = useHistory();
  const onClickHomePage = () => {
    history.goBack();
  };

  return (
    <div>
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        style={{ height: "100vh" }}
      >
        <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
          <Row gutter={[16, 16]} justify="center" align="middle">
            <Col span={24}>
              <Title>
                Oops! <br /> Page not found.
              </Title>
            </Col>
            <Col span={24}>
              <Text>
                The page you are looking for has been removed or doesn't exist!
              </Text>
            </Col>
            <Col span={24}>
              <Button type="ghost" onClick={onClickHomePage}>
                Go Back
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <PageNotFoundSVG />
        </Col>
      </Row>
    </div>
  );
}

export default PageNotFound;
