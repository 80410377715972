// Base Url of the system

import { notification } from "antd";
import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_MerchantDashboard_Api_URL,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      notification.info({
        message: "Your session has expired. Please login again.",
      });
      window.location.replace("/");
    }
    if (error.response?.status === 403) {
      notification.info({
        message: "You don't have permission to view this page!",
      });
    }
    return error;
  }
);

export default instance;
